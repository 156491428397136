@font-face {
	font-family: 'ESADE';
	src: url('./fonts/Esade-Regular.woff') format('woff'),
	url('./fonts/Esade-Regular.woff2') format('woff'),
	url('./fonts/Esade-Regular.eot') format('embedded-opentype'),
	url('./fonts/Esade-Regular.ttf') format('truetype');
}

.ESADEfont {
	font-family: 'ESADE';
}

.csv-search {
	border-radius: 20px;
    border: 1px solid black;
    box-shadow: 1px 1px rgb(0 0 0 / 50%);
    font-family: Arial;
    padding: 4px;
    width: 400px;
}

.fetch-button {
	padding: 6px 10px;
    border-radius: 4px;
    border: 1px solid rgba(0,0,0,0.5);
    cursor: pointer;
    background: #000b3d;
    color: #fff;
    margin-top: 10px;
}

.main {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.header-custom.vertical-blue {
	background: transparent;
	background-color: #000b3d;
}

.header-custom .Header-main {
	background-color: #000b3d;
}
.cabecera-link-titulo {
	font-family: Esade-Bold;
	color: #000b3d;
	line-height: 0.92;
	letter-spacing: -2.17px;
}

.diagonal-menu {
	top: 90px !important;
	border-bottom: 41px solid transparent;
	position: absolute;
	z-index: 999;
	top: 135px;
}

body .header-custom.vertical-blue {
	position: fixed;
	width: 100%;
	z-index: 110;
	padding: 0;
}

.title {
	margin-top: 50px;
    margin-left: 86px;
	font-family: 'ESADE';
}

.header-custom .main-logo {
	float: inherit;
	transform: translate(50%);
}

.center {
	margin: auto;
	width: 50%;
}

.page-controls {
	position: relative;
    bottom: 80px;
    left: 50%;
    background: white;
    transform: translateX(-50%);
    transition: opacity ease-in-out 0.2s;
    box-shadow: 0 30px 40px 0 rgb(16 36 94 / 20%);
    border-radius: 4px;
    display: inline-block;
	border: 1px solid #edebe9;
}
.page-controls span {
	font: inherit;
	font-size: 0.8em;
	padding: 0 0.5em;
}
.page-controls button {
	width: 44px;
	height: 44px;
	background: white;
	border: 0;
	font: inherit;
	font-size: 0.8em;
	border-radius: 4px;
}
.page-controls button:enabled:hover {
	cursor: pointer;
}
.page-controls button:enabled:hover,
.page-controls button:enabled:focus {
	background-color: #e6e6e6;
}
.page-controls button:first-child {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}
.page-controls button:last-child {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.react-pdf__Page__svg {
    width: auto !important;
    height: auto !important;
    border-radius: 8px;
}

.react-pdf__Page__svg svg {
    width: 90vw;
    height: auto;
    display: block;
    margin: 0 auto;
    border: 1px solid #edebe9;
	// padding: 10px;
	// background-color: #f4f4f4;
}

.react-pdf__Document {
	padding-bottom: 20px;
	// padding-top: 60px;
}

.download-button {
	background: #000b3d;
    color: white;
    border: 0px;
    margin: auto;
    width: fit-content;
    text-align: start;
	display: block;
	cursor: pointer;

	&.--text {
		display: inline-block;
		text-align: center !important;
		vertical-align: super;
	}
}

.react-pdf__Page__textContent {
	display: none !important;
}

/************ 
//--------------------------------
// GENERAL
//-------------------------------
************/
body {
	margin: 0;
	padding: 0;
	font-family: Helvetica, Arial, sans-serif;
	color: #666;
	background: #fff;
	font-size: 1em;
	line-height: 1.5em;
}

h1 {
	font-size: 2.3em;
	line-height: 1.3em;
	margin: 15px 0;
	text-align: center;
	font-weight: 300;
}

p {
	margin: 0 0 1.5em 0;
}

img {
	max-width: 100%;
	height: auto;
}

.App {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: 100vh;
}

/************ 
//-------------------------------
// CABECERA
//-------------------------------
************/
.main-header {
	background: #000b3d;
	color: white;
	height: 90px;
	width: 100%;
	left: 0;
	top: 0;

	.main-logo {
		height: 80px;

		.logo-img {
			margin: 5px 0px 0px 5px;
			cursor: pointer;
		}
	}
}
#main-header a {
	color: white;
}

/************ 
//------------------------------
// CONTENIDO
//------------------------------
************/
#main-content {
	background: white;
	flex: 0 0 100%;
}

#main-content header,
#main-content .content {
	padding: 40px;
}

/************
//-----------------------------
// PIE PÁGINA
//-----------------------------
************/
#main-footer {
	background: #000b3d;
	color: white;
	text-align: center;
	padding: 20px;
	margin-top: 40px;
}
#main-footer p {
	margin: 0;
}

#main-footer a {
	color: white;
}
